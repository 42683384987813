.section2 {
    height: 130px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(3, 96, 245, 0.08) 50%, rgba(0, 0, 0, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.section2 .container {
    text-align: center;
}

.section2-text {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: var(--gray-1);
}

.section2-highlight {
    font-weight: 500;
    color: white; /* White color for 'Our mission is simple:' */
}

@media (max-width: 768px) {
    .section2 {
        height: auto; /* Allow height to adjust */
        padding: 40px 20px; /* Reduce padding for tablets */
    }

    .section2 .container {
        padding: 0 40px; /* Reduced container padding */
    }

    .section2-text {
        font-size: 16px; /* Smaller font size for tablets */
    }
}

/* For Mobile (480px and below) */
@media (max-width: 480px) {
    .section2 {
        height: auto; /* Allow height to adjust for mobile */
        padding: 20px 10px; /* Further reduced padding for mobile */
    }

    .section2 .container {
        padding: 0 32px; /* Further reduce padding for mobile */
    }

    .section2-text {
        font-size: 13px; /* Smaller font size for mobile */
        line-height: 20px;
    }
}