/* Feature Carousel Styles */
.feature-carousel {
    display: grid;
    grid-template-columns: repeat(5, auto); /* 5 columns for the layout */
    gap: 32px; /* Gap between columns */
    padding-bottom: 120px;
    justify-content: center; /* Centers the columns horizontally */
}

.column {
    display: flex;
    flex-direction: column;
    gap: 32px; /* Space between items in each column */
    transition: transform 0.3s ease-out; /* Smooth transition for the parallax effect */
}

.feature1 {
    margin-top: 345px; /* Feature 1 specific top margin */
}

.feature2 {
    margin-top: 288px; /* Feature 2 specific top margin */
}

.feature3 {
    margin-top: 0px; /* Gap between feature 2 & 3 */
}

.feature4 {
    margin-top: 240px; /* Feature 4 specific top margin */
}

.feature5 {
    margin-top: 0px; /* Gap between feature 4 & 5 */
}

.feature6 {
    margin-top: 360px; /* Feature 6 specific top margin */
}

.feature7 {
    margin-top: 120px; /* Feature 7 specific top margin */
}

.feature8 {
    margin-top: 0px; /* Gap between feature 7 & 8 */
}

.feature {
    width: 300px; /* Adjust the size of the SVGs */
    height: auto;
}


/* For Tablets (768px and below) */
@media (max-width: 768px) {
    .feature-carousel {
        grid-template-columns: repeat(5, auto); /* 3 columns for tablets */
        gap: 20px; /* Further reduce the gap for  */
        padding-bottom: 80px;
        padding-top: 200px;
    }

    .column {
        
        gap: 20px; /* Space between items in each column */
        
    }

    .feature {
        width: 200px; /* Reduce the size of the features for tablets */
    }

    /* Adjust margins for each feature on tablets */
    .feature1, .feature2, .feature3, .feature4, .feature5, .feature6, .feature7, .feature8 {
        margin-top: 0px; /* Reset margins for a more compact layout on tablets */
    }
}

/* For Mobile (480px and below) */
@media (max-width: 480px) {
    .feature-carousel {
        grid-template-columns: repeat(5, auto); /* 2 columns for mobile */
        gap: 12px; /* Further reduce the gap for mobile */
        padding-bottom: 20px;
        padding-top: 140px;
    }

    .column {
        
        gap: 12px; /* Space between items in each column */
        
    }

    .feature {
        width: 120px; /* Reduce the size further for mobile */
    }

    /* Adjust margins for each feature on mobile */
    .feature1, .feature2, .feature3, .feature4, .feature5, .feature6, .feature7, .feature8 {
        margin-top: 0px; /* Reset margins for a more compact layout on mobile */
    }
}
