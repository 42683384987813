.contact-section {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 240px);
    max-width: 1200px;
    height: 214px;
    background-color: #080808;
    border: 1px solid #1A1A1A;
    margin: 0 auto;
    padding: 0;
    border-radius: 0px;
}

.contact-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 60px;
}

.contact-heading {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    width: 113px;
    margin-bottom: 12px;
}

.contact-description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-2);
    width: 220px;
    line-height: 24px;
}

.email-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 60px;
}

.email-box {
    display: flex;
    align-items: center;
    gap: 12px;
}

.email-input {
    width: 276px;
    height: 42px;
    background-color: rgb(28,28,30);
    border: 1px solid #333;
    padding: 12px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0.4vw rgba(0, 0, 0, 0.5), 0 0 0 0.15vw transparent;
    transition: 0.4s;
}

.email-input:hover {
    box-shadow: 0 0 0 0.15vw rgba(135, 207, 235, 0.186);
}

.email-input:focus {
    box-shadow: 0 0 0 0.15vw skyblue;
}

/* Updated send button with centered icon and text */
.send-button {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    width: 104px;
    height: 42px;
    background: #2B79EE; 
    color: #FFFFFF;
    padding: 0.7em 1em;
    display: flex;
    align-items: center; /* Center-align vertically */
    justify-content: center; /* Center-align horizontally */
    gap: 8px; /* Space between icon and text */
    border: none;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
}

.send-button span {
    display: block;
    transition: all 0.3s ease-in-out;
}

.send-button svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
}

.send-button:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
}

.send-button:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.send-button:hover span {
    transform: translateX(5em);
}

.send-button:active {
    transform: scale(0.95);
}

@keyframes fly-1 {
    from {
        transform: translateY(0.1em);
    }

    to {
        transform: translateY(-0.1em);
    }
}

.disclaimer {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
    width: 393px;
    margin-top: 16px;
    line-height: 18px;
}


/* For Tablets (768px and below) */
@media (max-width: 768px) {
    .contact-section {
        flex-direction: column;
        height: auto; /* Adjust height */
        padding: 40px 0 40px 0; /* Add padding for better spacing */
        margin-top: 150px;
        width: 85%; /* Full width for tablet */
        margin-left: auto;
        margin-right: auto; /* Center the entire section */
    }

    .contact-column {
        text-align: left; /* Align text to the left */
        margin-bottom: 24px; /* Add spacing between sections */
    }

    .contact-heading {
        font-size: 24px; /* Adjust heading size */
        margin-bottom: 12px;
        width: 100%;
    }

    .contact-description {
        font-size: 14px;
        width: 100%; /* Full width for description */
        margin: 0 auto; /* Center the text */
    }

    .email-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align the email input to the left */
        width: 100%; /* Full width for tablet */
        padding-left: 60px;
    }

    .email-box {
        display: flex;
        flex-direction: row; /* Keep input and button in one line */
        gap: 12px; /* Space between input and button */
        width: 180%;
    }

    .email-input {
        width: 70%; /* Set a proper width for email input */
        height: 42px;
    }

    .send-button {
        width: auto; /* Allow button width to adjust automatically */
    }

    .disclaimer {
        text-align: center;
        margin-top: 16px;
        font-size: 12px;
    }
}

/* For Mobile (480px and below) */
@media (max-width: 480px) {
    .contact-section {
        flex-direction: column;
        padding: 32px;
        height: auto;
        width: 80%; /* Full width for mobile */
        margin-top: 200px;
    }

    .contact-column {
        padding-left: 0;
        text-align: left;
        margin-bottom: 16px;
    }

    .contact-heading {
        font-size: 18px; /* Smaller heading for mobile */
        margin-bottom: 8px;
    }

    .contact-description {
        font-size: 13px;
        width: 100%; /* Full width for description */
        margin: 0 auto;
        padding-bottom: 20px;
    }

    .email-column {
        align-items: left; /* Center-align email input and button */
        
    }

    .email-box {
        flex-direction: column; /* Stack input and button */
        gap: 10px;
        width: 160%; /* Full width for the box */
        margin-left: -50px;
    }

    .email-input {
        width: 100%; /* Full width for the input */
        height: 38px;
    }

    .send-button {
        width: 100%; /* Full width for the button */
        height: 38px;
    }

    .disclaimer {
        text-align: left;
        font-size: 12px;
        margin-top: 12px;
    }
}
