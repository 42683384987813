/* Header Layout */
.header {
  position: fixed;
  top: 40px; /* 40px from the top */
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px; /* 40px margin for both sides */
  z-index: 100;
  background-color: transparent; /* No background */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Adds only the logo icon and hides the name on scroll */
.header.scrolled .logo-name {
  opacity: 0;
  transform: translateX(-20px); /* Fade and move the name to the left on scroll */
}

/* Logo Style */
.logo {
  display: flex;
  align-items: center;
  margin-left: 0px; /* Adjust as needed */
}

/* Icon part of the logo */
.logo-icon {
  width: 60px; /* Adjust the icon size */
  height: 60px;
}

/* Name part of the logo */
.logo-name {
  font-size: 32px;
  font-weight: 500;
  font-family: Georgia, 'Times New Roman', Times, serif ;
  color: white;
  margin-left: 6px;
  margin-top: 12px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Button Style but smaller */
/* Button Style but smaller */
.smaller-button {
  --primary-color: #0F2975;  
  --hover-color: #165EF8;    
  --border_radius: 6px; /* Reduce corner radius */
  --black-700: hsla(0 0% 12% / 1);
  --transtion: 1s ease-in-out; /* Smoother transition with longer duration */
  --offset: 2px;

  cursor: pointer;
  position: relative;

  display: flex;
  align-items: center;
  gap: 0.4rem; /* Smaller gap */

  transform-origin: center;

  padding: 0.5rem 1rem; /* Smaller padding for smaller button */
  background-color: var(--primary-color);

  border: 2px solid var(--primary-color);
  border-radius: var(--border_radius);
  transform: scale(calc(1 + (var(--active, 0) * 0.1)));

  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Longer smooth transition */
  opacity: 1; 
}

.hide-button {
  opacity: 0; /* Fade out smoothly */
  pointer-events: none; /* Disable interactions when hidden */
  transition: opacity 1s ease-in-out; /* Smooth fade-out transition */
}

/* The rest of the styles stay the same */


.smaller-button:hover {
    background-color: var(--hover-color);
}

.smaller-button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
  
    border-radius: var(--border_radius);
    box-shadow: inset 0 0.5px hsl(0, 0%, 100%), inset 0 -1px 2px 0 hsl(0, 0%, 0%),
      0px 4px 10px -4px hsla(0 0% 0% / calc(1 - var(--active, 0))),
      0 0 0 calc(var(--active, 0) * 0.375rem) hsla(221, 94%, 53%, 0.75);
  
    transition: all var(--transtion);
    z-index: 0;
}

.smaller-button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: 100%;
    height: 100%;
    background-color: var(--hover-color);
    background-image: none;
  
    opacity: var(--active, 0);
    border-radius: var(--border_radius);
    transition: opacity var(--transtion);
    z-index: 2;
}

.smaller-button:is(:hover, :focus-visible) {
    --active: 1;
}

.smaller-button:active {
    transform: scale(1);
}

.smaller-button .dots_border {
    --size_border: calc(100% + 2px);
  
    overflow: hidden;
  
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: var(--size_border);
    height: var(--size_border);
    background-color: transparent;
  
    border-radius: var(--border_radius);
    z-index: -10;
}

.smaller-button .sparkle {
    position: relative;
    z-index: 10;
  
    width: 1.2rem;
}

.smaller-button .text_button {
    font-size: 0.75rem;
    font-weight: 300;
    color: #ffffff;
}
.no-underline {
  text-decoration: none; /* Removes the underline */
}

/* Navigation Styling */
.nav {
  display: flex;
  justify-content: center; /* Center the tabs horizontally */
  align-items: center;
  gap: 2rem; /* Space between "Home" and "Blog" */
}

.nav-link {
  color: #a09f9f;
  text-decoration: none; /* Remove underline */
  font-size: 1.2rem; /* Default font size */
  font-weight: 300; /* Normal font weight */
  transition: transform 0.3s ease, color 0.3s ease, opacity 0.3s ease; /* Smooth hover animation */
  opacity: 1; /* Default opacity for unhighlighted links */
}

.nav-link:first-child {
  color: white; /* Bright white color for Home tab */
  font-weight: 400; /* Medium weight for Home tab */
  opacity: 1; /* Full opacity for Home tab */
}

.nav-link:hover {
  color: #ffffff; /* Highlight color on hover */
  transform: scale(1.2); /* Slightly enlarge on hover */
  opacity: 1; /* Make the hovered tab fully opaque */
}



/* Responsive for Tablets (768px and below) */
@media (max-width: 768px) {
  .header {
    top: 40px; /* Bring the header closer to the top */
    padding: 0 40px; /* Reduce side padding */
  }

  .logo-icon {
    width: 50px; /* Smaller logo for tablets */
    height: 50px;
  }

  .logo-name {
    font-size: 24px; /* Reduce the font size of the logo name for tablets */
    margin-top: 8px;
  }

  .smaller-button {
    padding: 0.2rem 0.4rem; /* Adjust button padding for tablets */
    font-size: 0.6rem; /* Further reduce button text size */
  }

  .smaller-button .text_button {
    font-size: 0.6rem; /* Smaller button text size for tablets */
  }
}

/* Responsive for Mobile (480px and below) */
@media (max-width: 480px) {
  .header {
    top: 20px; /* Bring the header even closer to the top */
    padding: 0 20px; /* Further reduce side padding */
  }

  .logo-icon {
    width: 40px; /* Smaller logo for mobile */
    height: 40px;
  }

  .logo-name {
    font-size: 18px; /* Reduce the font size of the logo name for mobile */
    margin-top: 4px;
  }

  .smaller-button {
    padding: 0.3rem 0.6rem; /* Adjust button padding for mobile */
  }

  .smaller-button .text_button {
    font-size: 0.65rem; /* Smaller button text size for mobile */
  }
}