/* Import the Inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

/* Define CSS Variables for Colors and Gradients */
:root {
  --background-color: #050505;
  --primary-color: #1890FF;
  --white: #FFFFFF;
  --gray-1: #ADADAD;
  --gray-2: rgba(114, 114, 120, 0.8);;
  --white-2: #E4E4E7;

  /* Gradient 1 (Photo 2) */
  --gradient-1-start: #081539;
  --gradient-1-mid: #0F2975;
  --gradient-1-end: #1890FF;

  /* Gradient 2 (Photo 3) */
  --gradient-2-start: #165EF8;
  --gradient-2-end: #0E3CD1;

  /* Gradient 3 (Photo 3) */
  --gradient-3-start: #1642C5;
  --gradient-3-end: #1890ff;

  /* Gradient Text (Photo 4) */
  --gradient-text-start: #FFFFFF;
  --gradient-text-end: #464647;
}

/* Apply Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Prevent horizontal scrolling */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* This will hide the horizontal scrollbar */
}


body {
  font-family: 'Inter', sans-serif;
  background-color: var(--background-color);
  color: var(--white);
  line-height: 1.6;
}

/* Container Setup */
.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 120px;
}

/* Button styles */
button {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: var(--gradient-1-mid); /* Using the mid tone of Gradient 1 */
}


/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body styles */
body {
  font-family: 'Inter', sans-serif;
  background-color: #050505; /* Background color */
  color: #FFFFFF; /* Default text color */
  line-height: 1.6;
}

/* Layout container */
.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 120px; /* 120px side margins */
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  color: #FFFFFF; /* Headings will be white by default */
}

/* Basic button styles */
button {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  padding: 10px 20px;
  background-color: #1890FF; /* Primary color */
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0F2975; /* Darker blue for hover */
}
