/* WhySection.css */
.why-section {
    display: flex;
    height: 100vh;
    width: 100%;
    padding-top: 200px;
    padding-left: 120px;
    padding-right: 120px;
    padding-bottom: 120px;
    position: relative;
    overflow: hidden;
}

.left-heading {
    flex: 1;
    font-size: 40px;
    line-height: 72px;
    letter-spacing: -1.2px;
    background: linear-gradient(90deg, var(--gradient-text-start), var(--gradient-text-end));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    padding-right: 80px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    word-break: normal;
    width: auto;
}

.right-content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

/*.metrics-section,
.description-section {
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}*/

.metrics-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 32px;
    opacity: 1;
    transform: translateY(0);
}

.description-section {
    max-width: 500px;
    text-align: left;
    color: var(--gray-2);
    font-size: 16px;
    line-height: 1.6;
    opacity: 1;
    
    margin-top: 20px; /* Add some margin for spacing */
}

.box {
    background: #121212;
    width: 320px;
    height: 308px;
    border-radius: 12px;
    padding-top: 32px;
    padding-left: 24px; 
    padding-right: 24px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: white;
}

.box .metric {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 52px;
}

.box .title {
    font-size: 18px;
    font-weight: 500;
    width: 245px;
    margin-bottom: 16px;
}

.box .body {
    font-size: 14px;
    font-weight: 400;
    width: 242px;
    color: var(--gray-2);
    line-height: 24px;
}


/*.scroll-bar {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 634px;
    background-color: #0D0D0D;
    border-radius: 12px;
}

.scroll-bar-inner {
    position: absolute;
    width: 8px;
    height: 50%;
    background: linear-gradient(180deg, var(--gradient-1-start), var(--gradient-1-end));
    border-radius: 12px;
    transition: height 0.6s ease-in-out;
}

.scroll-bar-inner.full {
    height: 634px;
}

.scroll-bar-inner.half {
    height: 317px;
}*/

/* Responsive for Tablets (768px and below) */
@media (max-width: 768px) {
    .why-section {
        padding-top: 100px;
        padding-left: 80px;
        padding-right: 40px;
        padding-bottom: 80px;
        height: auto; /* Allow the section height to adjust based on content */
        flex-direction: column; /* Stack everything vertically */
    }

    .left-heading {
        font-size: 32px;
        line-height: 52px;
        padding-right: 40px;
        text-align: left;
    }

    .metrics-section {
        grid-template-columns: 1fr 1fr; /* Keep two columns for tablets */
        gap: 24px; /* Reduce gap between boxes */
        padding-top: 40px;
        margin-left: -80px;
    }

    .box {
        width: 260px; /* Reduce box width for tablets */
        height: auto; /* Allow box height to adjust */
    }

    .box .metric {
        font-size: 28px;
    }

    .box .title {
        font-size: 14px;
    }

    .box .body {
        font-size: 12px;
        width: 200px;
    }

    .description-section {
        max-width: 100%;
        font-size: 14px;
        text-align: center;
    }
}

/* Responsive for Mobile (480px and below) */
@media (max-width: 480px) {
    .why-section {
        padding-top: 120px;
        padding-left: 40px;
        padding-right: 20px;
        padding-bottom: 60px;
        height: auto;
        flex-direction: column; /* Stack everything vertically */
    }

    .left-heading {
        font-size: 20px;
        line-height: 28px;
        text-align: left;
        
    }

    .metrics-section {
        padding-left: 20px;
        grid-template-columns: 1fr; /* Switch to a single column for mobile */
        gap: 20px; /* Reduce gap further */
    }

    .box {
        width: 100%; /* Full width for mobile */
        height: auto;

    }

    .box .metric {
        font-size: 24px;
    }

    .box .title {
        font-size: 14px;
    }

    .box .body {
        font-size: 12px;
        width: 240px;
    }

    .description-section {
        max-width: 100%;
        font-size: 12px;
        text-align: center;
    }
}