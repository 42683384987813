/* Hero Section Styles */
.hero {
    height: 100vh;
    background: radial-gradient(circle at top, rgba(3, 96, 245, 0.15) 0%, rgba(3, 96, 245, 0) 50%),
                var(--background-color);
    display: flex;
    align-items: center;
    color: var(--white);
    position: relative;
    z-index: 2; /* Content z-index */
}

.hero::before {
    content: '';
    position: absolute;
    width: 800px; /* Wider circle */
    height: 800px;
    top: -100px; /* Adjust to reflect slightly above */
    left: 100px; /* Adjust for perfect alignment */
    background: radial-gradient(circle, rgba(3, 96, 245, 0.25) 0%, rgba(3, 96, 245, 0) 80%);
    z-index: 2; /* Ensure it's behind other elements */
    filter: blur(400px); /* Soften the reflection */
}
  
.hero .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1; /* Content should be above the circle */
}
  
.hero-text {
    max-width: 900px; /* Reduced width to fix heading */
}
  
/* Heading styles */
.hero-heading {
    font-family: 'Inter', sans-serif;
    font-size: 42px; /* Font size from the design */
    font-weight: bold;
    line-height: 54px;
    letter-spacing: 2px;
    color: var(--white);
    margin-top: 100px;
    margin-bottom: 32px; /* Space between heading and subheading */
}
  
/* Subheading styles */
.hero-subheading {
    font-family: 'Inter', sans-serif;
    font-size: 22px; /* Font size from the design */
    font-weight: 300;
    line-height: 36px;
    letter-spacing: -0.24px;
    color: var(--gray-1); /* Gray color */
    max-width: 611px; /* Restrict subheading width */
}
  
.hero-image {
    display: flex;
    justify-content: flex-end; /* Keep the image aligned to the right */
    z-index: 1;
    margin-top: 100px;
}
  
.hero-image img {
    width: 100%; /* Allow image to take full width */
    max-width: 700px; /* Increase maximum width to make the image bigger */
    height: auto;
    margin-left: 0px; /* Space between text and image */
    z-index: 1;
}

/* Swirl Effect Styles */
.swirl-cursor {
  position: absolute;
  pointer-events: none;
  width: 100px;
  height: 100px;
  background: radial-gradient(circle, rgba(3, 96, 245, 0.25), rgba(3, 96, 245, 0));
  border-radius: 50%;
  filter: blur(20px);
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: transform 0.2s ease-out, opacity 0.5s ease-out;
}

.hero:hover .swirl-cursor {
  opacity: 1; /* Show cursor effect on hover */
}

/* For Tablets (768px and below) */
@media (max-width: 768px) {
    .hero {
      height: auto;
      padding: 40px 20px;
    }
  
    .hero .container {
      flex-direction: column;
      align-items: center;
      text-align: left;
      padding: 0 60px;
      max-width: 100%;
      padding-top: 80px;
    }

    .hero-image {
        margin-top: 30px;
      }
  
    .hero-text {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .hero-heading {
      font-size: 32px;
      line-height: 40px;
      margin-top: 40px;
    }
  
    .hero-subheading {
      font-size: 18px;
      line-height: 28px;
      max-width: 90%;
    }
  
    
  
    .hero-image img {
      max-width: 450px;
    }
}
  
/* For Mobile (480px and below) */
@media (max-width: 480px) {
    .hero {
      padding: 40px 10px;
    }

    .hero .container{
        padding-left:32px;
        padding-right: 32px; /* Further reduced padding for mobile */
        max-width: 100%; /* Allow container to take full width */
        
    }
  
    .hero-heading {
      font-size: 20px;
      line-height: 28px;
      
      margin-bottom: 24px;
      
    }
  
    .hero-subheading {
      font-size: 13px;
      line-height: 24px;
    }
  
    .hero-image img {
      max-width: 420px;
    }
}