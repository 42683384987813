/* Footer Section */
.footer-section {
    height: 360px;
    background-color: #0A0A0A;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    position: relative;
    overflow: hidden;
    margin-top: 100px;
    padding-top: 0px; /* Reduced top padding */
}

/* For the Coming Soon Text */
.text-layer-wrapper {
    position: relative;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    width: 100%;             /* Ensure it takes full width */
    height: 100%;            /* Ensure it takes full height */
}

.coming-soon {
    font-family: 'Lora', serif;
    font-weight: bold;
    font-size: 260px;
    line-height: 1;
    letter-spacing: 0px;
    color: #0D0D0D;
    position: absolute;
    white-space: nowrap;
    text-align: center; /* Center the text */
}

/* Styling for top and bottom text layers */
.top-layer {
    opacity: 0.7;
    color: #0D0D0D;
    text-shadow: 0 0 4px rgba(36, 36, 36, 1);
}

.bottom-layer {
    opacity: 1;
    color: #0D0D0D;
    text-shadow: 0 0 1px #242424;
}

/* Footer box container */
.footer-box {
    width: 1200px;
    background-color: #080808;
    border-color: #1A1A1A;
    height: 232px;
    margin: 0 auto; /* Center the footer box */
    padding: 0 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-background {
    background-color: #0A0A0A;
    width: 100%;
    height: 466px;
    position: relative;
    display: flex;
    justify-content: center; /* Center the footer box */
}

/* Responsive for Tablets (768px and below) */
@media (max-width: 768px) {
    .footer-box {
        width: 85%; /* Reduced width for tablet view */
        padding: 0 30px;
        flex-direction: column; /* Stack content vertically */
        height: auto; /* Let height adjust automatically */
        align-items: flex-start; /* Align items to the left */
        text-align: left;
    }

    .footer-section {
        height: auto; /* Let the height adjust automatically */
        padding: 40px 0; /* Add padding for better spacing */
    }

    .coming-soon {
        font-size: 120px; /* Adjust font size for tablets */
    }
}

/* Responsive for Mobile (480px and below) */
@media (max-width: 480px) {
    .footer-box {
        width: 95%; /* Full width for mobile view */
        padding: 0 20px;
        flex-direction: column; /* Stack content vertically */
        height: auto; /* Let height adjust automatically */
        align-items: flex-start; /* Align items to the left */
        text-align: left;
    }

    .footer-section {
        height: auto; /* Let the height adjust automatically */
        padding: 30px 0; /* Add padding for better spacing */
    }

    .coming-soon {
        font-size: 80px; /* Adjust font size for mobile */
    }

    .text-layer-wrapper {
        height: auto; /* Adjust height for mobile */
        padding: 20px;
    }
}