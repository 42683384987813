/* Container for the whole feature description section */

.feature-description-container {
    padding-top: 200px;
    padding-left: 120px;
    padding-right: 120px;
    position: relative; /* Needed for pseudo-elements */

}

.feature-description-heading {
    text-align: left; /* Left-aligned heading */
    margin-bottom: 60px;
}

.feature-description-heading h2 {
    font-family: 'Inter', sans-serif;
    font-size: 48.2px;
    line-height: 51.4px;
    font-weight: bold;
    letter-spacing: -1.2px;
    color: var(--white);
}

.healthcare-text-gradient {
    color: #1890FF;
}

/* Grid layout for the feature sections */
.feature-description-grid {
    display: grid;
    grid-template-columns: 482px 1fr 1fr;
    grid-template-rows: auto;
    gap: 0px;
}




/* Section 1 */
.section-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 3; /* Section 1 spans 2 rows */
    padding-top: 60px;
    padding-left: 44px;
    border-top: 1px solid #242424;
    border-left: 1px solid #242424;
    border-right: 1px solid #242424;
    
}

.section-1 .feature-icon {
    width: 102px;
    height: 102px;
    margin-bottom: 32px;
}

.section-1 h3 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: 500;
    margin-bottom: 20px;
    width: 376px; /* Set the width */

}

.section-1 p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    color: var(--gray-2);
    margin-bottom: 50px;
    width: 367px; /* Set the width */

}

.section-1 .feature-image {
    width: 358px;
    height: 358px;
}

/* Section 2 */
.section-2 {
    grid-column: 2 / 4; /* Spans both columns in row 2 */
    grid-row: 1 / 2;
    padding: 0px 48px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #242424;
    border-right: 1px solid #242424;
    border-bottom: 1px solid #242424; /* Ensure border is explicitly set */

    
}

.section-2 .text-content {
    display: flex;
    flex-direction: column; /* Stack title and description vertically */
    justify-content: center;
}

.section-2 h3 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: 500;
    margin-bottom: 20px; /* Gap between title and description */
    width: 257px;
}

.section-2 p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    color: var(--gray-2);
    margin-bottom: 0px;
    width: 257px;
}

.section-2 .feature-image {
    width: 400px;
    height: 315px;
    margin-left: 40px;
    display: flex;
    align-items: center;
}


/* Section 3 */
.section-3 {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    padding: 40px 48px 20px;
    color: var(--white);
    border-right: 1px solid #242424;
}

.section-3 .feature-icon {
    width: 277px; /* Width: 277px */
    height: 191px; /* Height: 191px */
    margin-bottom: 40px; /* Gap between SVG and title: 40px */
}

.section-3 h3 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    margin-bottom: 20px; /* Gap between title and description: 20px */
    width: 270px;
}

.section-3 p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: 300;
    color: var(--gray-2);
    width: 270px;
}

/* Section 4 */
.section-4 {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    padding-top: 48px; /* Upper padding: 48px, Left padding/margin: 36px */
    padding-left: 36px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 1px solid #242424;
}

.section-4 h3 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    padding-bottom: 10px; /* Gap between title and description: 20px */
    width: 279px;
}

.section-4 p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    color: var(--gray-2);
    padding-bottom: 40px;
    width: 307px;
}

.section-4 .feature-image {
    width: 329px; /* Width: 329px */
    height: 124px; /* Height: 124px */
    align-self: flex-end; /* Align the SVG to the right */
}


/* Section 5 */
.section-5 {
    grid-column: 1 / 4;
    grid-row: 3 / 4;
    padding-top: 24px;
    padding-left: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #242424;
}

.section-5 h3 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: 500;
    margin-bottom: 20px; /* Gap between title and description */
    width: 406px;
}

.section-5 p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: 300;
    color: var(--gray-2);
    width: 406px;
}

.section-5 .feature-image {
    width: 692px;
    height: 246px;
    margin-left: 40px;
    display: flex;
    align-items: center;
}


/* General styles for feature items */
.feature-item h3 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
}

.feature-item p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgba(161, 161, 170, 0.5); /* 80% opacity */
    margin-bottom: 20px;
}

.feature-icon, .feature-image {
    width: 100%;
    height: auto;
    margin-top: 0px;
}

/* For Tablets (768px and below) */
@media (max-width: 768px) {
    .feature-description-container {
        padding-left: 60px;
        padding-right: 60px;
        width: 100%;
    }

    .feature-description-grid {
        display: block;
        width: 100%;
        
    }

    .feature-description-heading h2 {
        font-size: 32px; /* Further reduce for mobile */
        line-height: 40px;
        text-align: Left;
        padding-left: 20px;
    }
    .section-1 {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 32px 32PX 32px 32px;
        width: 100%;
    }

    /* Vertical stacking for icon, heading, and description */
    .section-1 .text-content {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        width: 60%; /* Text content takes 60% of the width */
        text-align: left;
    }

    .section-1 .feature-icon {
        width: 70px; /* Adjust icon size */
        height: 70px;
        margin-bottom: 16px; /* Space between icon and heading */
    }

    .section-1 h3 {
        
        width: 90%; /* Text takes 60% width */
        font-size: 18px;
        margin-right: 40PX;

    }

    .section-1 p {
        
        width: 90%; /* Text takes 60% width */
        font-size: 13px;

    }

    /* SVG image on the right */
    .section-1 .feature-image {
        width: 45%; /* Image takes 35% of the width */
        height: auto;
        display: flex;
        justify-content: center; /* Center the image */
        align-items: center;
    }

    /* No need to change Section 2 and beyond */



    /* Section 2: Full width, text on the left, image on the right */
    .section-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 0 32px 32px;
        width: 100%;
        border-left: 1px solid #242424;
    }

    .section-2 h3{
        width: 100%; /* Text takes 60% width */
        font-size: 18px;
        

    } 
    
    .section-2 p {
        width: 100%; /* Text takes 60% width */
        
        font-size: 13px;

    }

    .section-2 .feature-image {
        width: 50%; /* Image takes 35% width */
        height: auto;
        padding-right: 40px;
        
    }

    /* Section 3 and Section 4: Sharing the same row */
    .section-3, .section-4 {
        display: inline-block;
        width: 50%; /* Each section takes 50% of the row */
        padding-left: 32px;
        padding-top: 32px;
        vertical-align: top;
        
    }

    .section-3{
        border-left: 1px solid #242424;

    }

    .section-3 h3{
        
        width: 100%; /* Text takes 60% width */
        font-size: 18px;
    }
        
    .section-3 p{

        width: 100%; /* Text takes 60% width */
        font-size: 13px;
    }

    .section-4 h3{
        
        width: 100%; /* Text takes 60% width */
        font-size: 18px;
        padding-right: 20px;
    } 
        
    .section-4 p {

        width: 100%; /* Text takes 60% width */
        font-size: 13px;
        padding-right: 20px;
    }

    .section-3 .feature-image{
        width: 100%; /* Full width for images */
        height: auto;

    }
    .section-4 .feature-image {
        width: 100%; /* Full width for images */
        height: auto;
        padding-bottom: 68px;
    }

    /* Section 5: Full width, text on the left, image on the right */
    .section-5 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 0;
        width: 100%;
        padding-left: 40px;
    }

    .section-5 h3{
        
        width: 100%; /* Text takes 60% width */
        font-size: 18px;
    } 
    
    .section-5 p {
        width: 100%; /* Text takes 60% width */
        font-size: 13px;
    }

    .section-5 .feature-image {
        width: 50%; /* Image takes 35% width */
        height: auto;
    }
}



/* For Mobile (480px and below) */
@media (max-width: 480px) {
    .feature-description-container {
        padding-left: 40px;
        padding-right: 40px;
        width: 100%;
    }

    .feature-description-grid {
        
        width: 100%;
    }

    .feature-description-heading h2 {
        font-size: 24px; /* Reduce heading size */
        line-height: 28px;
        text-align: left;
        
    }

    /* Section 1 */
    .section-1 {
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        padding: 32px;
        width: 100%;
        margin-bottom: 20px;
        border: 1px solid #242424;
    }

    .section-1 .feature-icon {
        
        width: 70px;
        height: 70px;
        
    }

    .section-1 h3 {
        width: 160%;
        font-size: 16px;
    }

    .section-1 p {
        width: 160%;
        font-size: 13px;
        line-height: 20px;
    }

    .section-1 .feature-image {
        width: 100%; /* Full width for images */
        height: auto;
        margin-top: 20px;
    }

    /* Section 2 */
    .section-2 {
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        padding: 32px;
        margin-bottom: 20px;
        width: 100%;
        border: 1px solid #242424;
    }

    .section-2 h3{

        width: 100%;
        font-size: 16px;
    } 
    
    .section-2 p {
        width: 100%;
        font-size: 13px;
        line-height: 20px;
    }

    .section-2 .feature-image {
        width: 100%;
        height: auto;
       
    }

    /* Section 3 and Section 4: Full width, vertically stacked */
    .section-3, .section-4 {
        
        flex-direction: column;
        padding: 32px 0 32px 32px;
        width: 100%;
        margin-bottom: 20px;
        border: 1px solid #242424;
    }

    .section-3{

        padding-right: 32px;
    }
    .section-3 h3{
        
        width: 100%;
        font-size: 16px;}
    
    .section-4 h3 {
        width: 100%;
        font-size: 16px;
        padding-right: 32px;
    }

    .section-3 p{
        width: 100%;
        font-size: 13px;
        line-height: 20px;
    } 
    
    .section-4 p {
        width: 100%;
        font-size: 13px;
        line-height: 20px;
        padding-right: 32px;
        
    }

    .section-3 .feature-image{

        width: 100%;
        height: auto;
        padding-left: 120px;
        
    }
        
    .section-4 .feature-image {
        width: 100%;
        height: auto;
        padding-bottom: 20px;
        
    }

    /* Section 5 */
    .section-5 {
        display: flex;
        flex-direction: column;
        padding: 32px;
        width: 100%;
        border: 1px solid #242424;
    }

    .section-5 h3{
        width: 100%;
        font-size: 16px;
        

    } 
    
    .section-5 p {
        width: 100%;
        font-size: 13px;
        line-height: 20px;

    }

    .section-5 .feature-image {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }
}

