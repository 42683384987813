.community-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 200px;
    padding-bottom: 200px;
    position: relative;
    overflow: hidden;
}

.background-sphere {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 100px;
    transform: translate(-50%, -50%);
    background-color: rgba(3, 96, 245, 1);
    opacity: 1;
    filter: blur(200px);
    z-index: -1;
}

.community-heading {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 48.2px;
    line-height: 51.4px;
    letter-spacing: -1.2px;
    color: var(--white);
    width: 434px;
    margin-bottom: 24px;
}

.community-subheading {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: rgba(239, 239, 239, 0.7);
    width: 868px;
    margin-bottom: 64px;
    line-height: 24px;
}

.community-features {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-bottom: 54px;
}

.feature-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature-icon {
    width: 56px;
    height: 56px;
    margin-bottom: 0px;
}

.feature-title {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 12px;
    width: 186px;
}

.feature-description {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: var(--gray-2);
    text-align: center;
    width: 228px;
    line-height: 18px;
}
.button {
    --primary-color: #2B79EE;  /* Set the primary color */
    --hover-color: #165EF8;    /* Set the hover color */
    --border_radius: 8px;  /* Set corner radius to 6px */
    --black-700: hsla(0 0% 12% / 1);
    --transtion: 0.3s ease-in-out;
    --offset: 2px;
  
    cursor: pointer;
    position: relative;
  
    display: flex;
    align-items: center;
    gap: 0.5rem;
  
    transform-origin: center;
  
    padding: 1rem 2rem;
    background-color: var(--primary-color); /* Primary color for the button */
  
    border: 0px solid var(--primary-color); /* Set border to primary color */
    border-radius: var(--border_radius);    /* Corner radius set to 6px */
    transform: scale(calc(1 + (var(--active, 0) * 0.1)));
  
    transition: transform var(--transtion), background-color var(--transtion);
}

.button:hover {
    background-color: var(--hover-color); /* Solid hover color */
}

.button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: 100%;
    height: 100%;
    background-color: var(--primary-color); /* Ensure before pseudo-element matches primary color */
  
    border-radius: var(--border_radius);  /* Ensure the corner radius is 6px here too */
    box-shadow: inset 0 0.5px hsl(0, 0%, 100%), inset 0 -1px 2px 0 hsl(0, 0%, 0%),
      0px 4px 10px -4px hsla(0 0% 0% / calc(1 - var(--active, 0))),
      0 0 0 calc(var(--active, 0) * 0.375rem) hsla(221, 94%, 53%, 0.75);
  
    transition: all var(--transtion);
    z-index: 0;
}

.button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: 100%;
    height: 100%;
    background-color: var(--hover-color); /* Hover color */
    background-image: none;  /* Remove gradient */
  
    opacity: var(--active, 0);
    border-radius: var(--border_radius);  /* Ensure corner radius remains 6px here */
    transition: opacity var(--transtion);
    z-index: 2;
}

.button:is(:hover, :focus-visible) {
    --active: 1;
}

.button:active {
    transform: scale(1);
}

.button .dots_border {
    --size_border: calc(100% + 2px);
  
    overflow: hidden;
  
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: var(--size_border);
    height: var(--size_border);
    background-color: transparent;
  
    border-radius: var(--border_radius);  /* Ensure corner radius is 6px */
    z-index: -10;
}

.button .dots_border::before {
    content: "";
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left;
    transform: rotate(0deg);
  
    width: 100%;
    height: 2rem;
    background-color: white;
  
    mask: linear-gradient(transparent 0%, white 120%);
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    to {
      transform: rotate(360deg);
    }
}

.button .sparkle {
    position: relative;
    z-index: 10;
  
    width: 1.75rem;
}

.button .sparkle .path {
    fill: currentColor;
    stroke: currentColor;
  
    transform-origin: center;
  
    color: hsl(0, 0%, 100%);
}

.button:is(:hover, :focus) .sparkle .path {
    animation: path 1.5s linear 0.5s infinite;
}

.button .sparkle .path:nth-child(1) {
    --scale_path_1: 1.2;
}

.button .sparkle .path:nth-child(2) {
    --scale_path_2: 1.2;
}

.button .sparkle .path:nth-child(3) {
    --scale_path_3: 1.2;
}

@keyframes path {
    0%,
    34%,
    71%,
    100% {
      transform: scale(1);
    }
    17% {
      transform: scale(var(--scale_path_1, 1));
    }
    49% {
      transform: scale(var(--scale_path_2, 1));
    }
    83% {
      transform: scale(var(--scale_path_3, 1));
    }
}

/* Update text to solid white */
.button .text_button {
    position: relative;
    z-index: 10;
  
    font-size: 1rem;
    font-weight: 300;
    color: #ffffff; /* Solid white text */
}

.no-underline {
    text-decoration: none; /* Removes the underline */
}

/* For Tablets (768px and below) */
@media (max-width: 768px) {
    .community-section {
        padding-top: 200px; /* Reduced padding */
        padding-bottom: 100px;
        padding-left: 80px;
    }

    .community-heading {
        font-size: 28px; /* Smaller heading */
        line-height: 38px;
        width: 100%; /* Full width */
    }

    .community-subheading {
        font-size: 14px; /* Smaller subheading */
        width: 100%;
        margin-bottom: 40px; /* Reduce margin */
    }

    .community-features {
        display: flex;
        flex-direction: column; /* Stack features vertically */
        align-items: center;
        gap: 20px;
    }

    .feature-title {
        font-size: 16px; /* Smaller title */
    }

    .feature-description {
        font-size: 13px;
        width: 100%;
        padding-left: 80px;
        padding-right: 80px;
    }
}

/* For Mobile (480px and below) */
@media (max-width: 480px) {
    .community-section {
        padding-top: 200px; /* Further reduced padding */
        padding-bottom: 50px;
        padding-left: 40px;
        padding-right: 40px;
        
    }

    .community-heading {
        font-size: 24px; /* Smaller heading */
        line-height: 36px;
        width: 100%;
    }

    .community-subheading {
        font-size: 13px; /* Smaller subheading */
        width: 100%;
        margin-bottom: 40px; /* Further reduce margin */
    }

    .community-features {
        display: flex;
        flex-direction: column; /* Stack features vertically */
        gap: 24px; /* Reduced gap */
    }

    .feature-box {
        align-items: center;
    }

    .feature-title {
        font-size: 13px; /* Smaller title */
    }

    .feature-description {
        font-size: 12px; /* Smaller description */
        width: 120%
        
    }

    .button {
        padding: 0.5rem 1rem; /* Reduce button padding */
        font-size: 14px; /* Smaller text */
    }
}
