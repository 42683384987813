/* FormModal.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dim background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.form-modal {
    background-color: #fff;
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12px;
    border-radius: 12px; /* More rounded border */
    max-width: 400px;
    width: 100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1001;
    display: flex;
    flex-direction: column;
}

.modal__body {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 12px;
    padding-right: 12px;
}

.button--icon {
    font-size: 1.25rem;
    background: none; /* Ensure no background */
    border: none;
    cursor: pointer;
    color: #333;
    transition: transform 0.2s ease;
    position: absolute;
    top: 12px;
    right: 20px;
    padding: 0; /* Remove padding to avoid any default styling */
    outline: none; /* Remove outline */
}

.button--icon:hover {
    transform: scale(1.2); /* Only scale the icon */
    background: none; /* Ensure background stays none on hover */
}


.input {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.input__label {
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: 5px;
    color: #333;
    text-align: left;
}

/* Updated text field styling */
.input__field {
    width: 100%;
    height: 42px;
    background-color: #f9f9f9; /* Slightly darker than white */
    border: 1px solid #c0c0c0; /* Set border color */
    padding: 12px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #333;
    border-radius: 8px; /* Slightly rounded corners */
    outline: none; /* Remove outline */
}

.input__field:focus {
    border-color: #d6d6d6; /* Keep the same border color on focus */
}

.modal__footer {
    text-align: center;
    margin-top: 30px;
}

.button--primary {
    background-color: #2B79EE;
    color: #fff;
    padding: 16px;
    border-radius: 8px;
    font-size: 0.9rem;
    width: 100%;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.button--primary:disabled {
    background-color: #2B79EE;
    cursor: not-allowed;
    opacity: 0.5;
}

.input__field--error {
    border-color: #ff4d4f; /* Red border for error */
}

.input__error {
    color: #ff4d4f;
    font-size: 0.75rem;
    margin-top: 5px;
}