/* BenefitSection.css */
.benefit-section {
  margin-top: 200px;
}

.benefit-heading {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 48.2px;
  line-height: 51.4px;
  letter-spacing: -1.2px;
  color: var(--white);
  margin-bottom: 56px;
}

.gradient-text {
  color: #1890FF;
}

.benefit-container {
  position: relative;
}

.benefit-boxes {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.benefit-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  height: 156px;
  background-color: #121212;
  padding: 42px 48px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  border: 2px solid transparent; /* Border that will glow */
  transition: border 0.3s ease;  /* Smooth transition */
}

/* Hover effect - glowing border only on hover */
.benefit-box:hover {
  border: 2px solid #1890FF;
  box-shadow: 0px 0px 10px 0px #1890FF;
}

.text-group {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #E4E4E7;
  width: 360px;
  margin-bottom: 6px;
}

.metric {
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #FFFFFF;
  width: 347px;
}

.description {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #727278;
  width: 643px;
  margin-left: 110px;
}

/* For Tablets (768px and below) */
@media (max-width: 768px) {
  .benefit-section {
      margin-top: 200px; /* Reduce margin for smaller screens */
      padding-left: 60px;
      padding-right: 60px;
  }

  .benefit-heading {
      font-size: 28px; /* Smaller heading size */
      line-height: 38px;
      margin-bottom: 40px;
      text-align: left; /* Center align for smaller screens */
      padding-left: 20px;
  }

  .benefit-boxes {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%; /* Full width for tablet */
  }

  .benefit-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Stack content vertically */
      width: 100%; /* Full width for each box */
      height: auto; /* Auto height to fit content */
      padding: 32px; /* Reduce padding for smaller screens */
      border-radius: 16px;
  }

  .text-group {
      margin-bottom: 20px; /* Add margin between text and description */
      align-items: flex-start;
  }

  .title {
      font-size: 18px;
      width: 100%; /* Full width */
      margin-bottom: 6px;
  }

  .metric {
      font-size: 28px; /* Smaller font size for metrics */
      width: 100%; /* Full width */
  }

  .description {
      font-size: 14px;
      line-height: 22px;
      width: 100%; /* Full width for description */
      margin-left: 0; /* Remove margin for mobile */
  }
}

/* For Mobile (480px and below) */
@media (max-width: 480px) {
  .benefit-section {
      margin-top: 200px;
      padding-left: 40px;
      padding-right: 40px;
  }

  .benefit-heading {
      font-size: 20px; /* Smaller heading size */
      line-height: 28px;
      margin-bottom: 30px;
      text-align: left;
  }

  .benefit-boxes {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%; /* Full width for mobile */
  }

  .benefit-box {
      display: flex;
      flex-direction: column; /* Stack elements vertically */
      align-items: flex-start;
      width: 100%; /* Full width for each box */
      height: auto;
      padding: 32px 32px 32px 32px; /* Reduced padding */
      border-radius: 12px;
  }

  .text-group {
      align-items: flex-start; /* Align text to the start */
      margin-bottom: 12px;
  }

  .title {
      font-size: 15px; /* Smaller title size */
      width: 100%;
      margin-bottom: 6px;
  }

  .metric {
      font-size: 24px; /* Smaller font size for metrics */
      width: 100%;
  }

  .description {
      font-size: 12px; /* Smaller description text */
      line-height: 20px;
      width: 100%; /* Full width for description */
      margin-left: 0; /* No margin on mobile */
      padding-top: 10px;
  }
}
